#changelog {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

#changelog td,
#changelog th {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9px;
  text-align: left;
  padding: 3px;
  word-wrap: break-word;
  margin: auto;
}

#changelog th {
  font-weight: normal;
  background-color: #f5f5f2;
}

#changelog td {
  background-color: white;
  border-bottom: 0.5px solid black;
}

#alllog {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

#alllog td,
#alllog th {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 7px;
  text-align: left;
  padding: 3px;
  word-wrap: break-word;
  margin: auto;
}

#alllog th {
  background-color: #d3d3d3;
}

#alllog td {
  background-color: white;
  border-bottom: 1px solid black;
}

#alllogAttributes {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

#alllogAttributes th,
#alllogAttributes td {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  padding: 5px;
}

#alllogAttributes td {
  border-bottom: 0.5px solid #000000;
}

#alllogAttributes tr td:nth-child(2) {
  text-align: right;
}
