.appdrawerOwcdrawer {
  background: var(--one-color-cobas-white);
  width: 100%;
}
.appdrawerPanel {
  width: 250px;
  height: 100%;
  z-index: 9999;
}
.appdrawerHeader {
  width: 250px;
  display: flex;
  align-items: center;
  border-bottom: thin solid var(--one-color-interaction-disabled-neutral-3);
}
.appdrawerDrawercontent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: inset 7px 0 9px -7px rgb(0 0 0 / 70%);
}
.appdrawerMenuclearicon {
  margin: 8px;
  margin-left: auto;
}
.appdrawerIconColor {
  color: var(--one-color-foreground-neutral-1);
}
.appdrawerIconStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.appdrawerIcon {
  margin: 8px;
}
