.appBarStyled {
  padding: 0;
  margin: 0;
}
.appbarDoubleGlobalAreaWithoutEnv {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
  position: fixed;
  top: 0;
  z-index: 0;
}
.appbarDoubleGlobalAreaWithEnv {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
  position: fixed;
  top: 49px;
  z-index: 0;
}
